.select {
    position: relative;
    label {
        margin-bottom: 0;
        &>div {
            display: flex;
            align-items: center;
            width: 100%;
            height: 32px;
            // padding: 3px 3px 3px 8px;
            padding: 3px 0 3px 8px;
            background: #EDEEF4;
            border: 1px solid #DADADA;
            border-radius: 3px;
            // font-size: 16px;
            line-height: 1.5;
            // outline: none;
            .output-text {
                font-weight: normal;
                // font-size: 16px;
                line-height: 19px;
                color: #666666;
            }
            .dropdown-button {
                margin-left: auto;
                padding: 0 10px;
                border-left: 1px solid #DADADA;
                .btn {
                    outline: none;
                }
            }
        }
    }
    &__dropdown {
        position: absolute;
        //top: 57px;
        top: 34px;
        // bottom: 34px;
        left: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        border: 1px solid #DADADA;
        list-style: none;
        z-index: 1000;
        li {
            background: #EDEEF4;
            padding: 3px;
            color: #666666;
            &:hover {
                background: #F0F2FD;
            }
            &+li {
                border-top: 1px solid #DADADA;
            }
        }
    }
    &__group {
        margin-top: 11px;
        display: flex;
        flex-wrap: wrap;
        .selected-item {
            background: #EDEEF4;
            border: 1px solid #DADADA;
            border-radius: 4px;
            font-style: italic;
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            color: #666666;
            padding-left: 9px;
            margin: 0 10px 10px 0;
            .btn {
                margin-left: 3px;
                padding: 0 8px;
            }
        }
    }
    &--open {
        .dropdown-button {
            .btn {
                transform: rotate(180deg);
            }
        }
    }
}

.my-dropdown {
  top: -95px !important;
}
