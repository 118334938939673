.output {
  background-color: #ffffff !important;
  min-width: 100px;

  .text {
    color: #504a5b !important;
  }

  .button {
    border-left: none !important;
    cursor: pointer;
  }
}
