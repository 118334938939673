.save-btn {
  background: #53A9D7;
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 12px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  transition: .4s;
}

.save-btn:hover {
  background: #1D8BC5;
  transition: .4s;
}

.save-btn_textarea {
  padding: 12px 20px;
}

.save-btn_card {
  padding: 12px 14px;
}

.create-orgm {
  position: absolute;
  bottom: -60px;
  left: 0;
}

.modal-card {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: #f2f7f9;;
}

.settings-color-card,.settings-status-card {
  position: fixed;
  bottom: -50px;
  left: 0;
}