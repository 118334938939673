.dropdownItem {
  background: #ffffff !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #504a5b !important;
  padding: 12px 10px !important;
  border-bottom: 1px solid #faf8ff;
  border-top: none !important;
  transition: all 0.5s;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background: #faf8ff !important;
    color: #151617 !important;
  }
}
