.worker__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
}

.worker__name {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #878f93;
  margin: 0;
}

.worker__call-sign {
  text-transform: uppercase;
  font-family: "Arial", sans-serif;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin: 8px 0;
}

.worker__department-wrapper {
    width: 98px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2px;
}

.worker__department {
  font-family: "Arial", sans-serif;
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  color: #373530;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
