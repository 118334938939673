.single-select {
  position: relative;
  z-index: 10;
  padding: 8px 8px;
  background-color: #fff;
  margin: 0;
  cursor: pointer;
}

.selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  font-style: regular;
}

.selected-opened {
  color: #1D8BC5;
}

.option {
  display: none;
  list-style: none;
  padding: 18px 8px;
  margin: 0;
  position: absolute;
  top: 47px;
  right: 0;
  border-radius: 8px;
  background-color: white;
  width: max-content;
  box-shadow: 0 2px 6px rgba(93, 105, 124, 0.2);
}

.option-opened {
  display: block;
}

.value {
  padding: 8px;
  color: #373530;
  border-radius: 4px;
}

.value:hover {
  background-color: #EBEFF2;
}
