
.react-flow__node-input .react-flow__handle {
  background: transparent !important;
  bottom: 0;
}

.react-flow__node-input.selected, .react-flow__node-input.selected:hover {
  box-shadow: 0 2px 6px rgba(93, 105, 124, 0.2);
}

.react-flow__node-input {
  border-color: transparent !important;
}

.react-flow__handle-bottom {
  left: 1500%;
  border: none;
}

.react-flow__handle-top {
  top: 0 !important;
  border: none !important;
}

.react-flow__handle{
  border-top-left-radius: 2px !important;
  border: 1px dashed transparent !important;
  transition: 1s !important;
  &:hover{
    border: 1px dashed #0274ac !important;
    transition: .3s !important;
  }
}
.react-flow__handle-bottom {

}