.side-panel-right {
  position: fixed;
  top: 0;
  right: -300px;
  transition: all 0.5s linear;
  width: 300px;
  height: 100vh;
  background-color: white;
  color: #878f93;
  padding-bottom: 24px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__show-right {
    right: 0;
  }

  &__title {
    font-family: "Arial", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #373530;
  }
}

.filter {
  display: flex;
  height: 73px;
  width: 260px;
  border-bottom: solid 1px #ebeff2;
  align-items: center;
  justify-content: space-between;

  &__select {
    height: 24px;
  }
}

.users__cards {
  padding: 0px 20px;
  width: 100%;
  max-height: 730px;
  overflow-y: auto;
  margin-bottom: auto;
  //scroll
  ::-webkit-scrollbar-track {
    background-color: #e7e1f4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b9b0ce;
  }
}

.user-check {
  display: flex;
  height: 104px;
  width: 100%;
  border-top: solid 1px #ebeff2;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;

  &__text {
    font-family: "Arial", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #373530;
  }
}

.counter {
  font-family: "Arial", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  &__on-duty {
    color: #373530;
  }
}

.begin-count-button {
  font-family: "Arial", sans-serif;
  height: 34px;
  width: 63px;
  border: unset;
  border-radius: 8px;
  background-color: #53a9d7;
  color: white;
  font-size: 12px;
  font-weight: 500;
  outline: none;
  margin-left: 30px;
  cursor: pointer;

  &:hover {
    background-color: #3f97c7;
  }

  &:active {
    background-color: #3284af;
  }
}

.finish-count-button {
  font-family: "Arial", sans-serif;
  height: 34px;
  width: 63px;
  border: solid 1px #53a9d7;
  border-radius: 8px;
  background-color: white;
  color: #53a9d7;
  font-size: 12px;
  font-weight: 500;
  outline: none;
  margin-left: 30px;
  cursor: pointer;
}

.filter-panel__text {
  font-family: "Arial", sans-serif;
  user-select: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #878f93;
  margin: 0;
  padding: 0;
}

.display-none {
  display: none;
}
