.comments-wrapper {
  background: #F5F6F8;
  border-radius: 8px;
  outline: none;
  border: 1px solid transparent;
  resize: none;

  .wrapper-icons {
    border-radius: 4px;
    height: 30px;
  }

  .d-none {
    display: none;
  }

  .rdw-editor-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .rdw-dropdown-wrapper:hover {
    transition: .3s;
    box-shadow: 1px 1px 0 #bfbdbd;
    background-color: #FFFFFF;
  }

  //&:focus-within {
  //  border: 1px solid #53A9D7;
  //}
}

.card-comment, .card-document{
  width: 100%;
  position: absolute;
  margin-top: 100px;
  cursor: default;
  bottom: -23px;
  background: #F5F6F8;
  left: 0;
  transform: translate(0%, 100%);
  z-index: 1;
}

.comments-editor {
  cursor: auto;
  padding: 4px 8px;
  font-family: Arial, sans-serif;

}

.rdw-option-wrapper, .rdw-colorpicker-option, .rdw-colorpicker-cube {
  border-radius: 5px;
}

.public-DraftStyleDefault-block, .public-DraftStyleDefault-ltr{
  h1,h2,h3,h4,h5,h6 {
    margin: 3px 0 0 10px;
  }
}
