.filter {
  position: absolute;
  right: 0;
  top: 70px;
  z-index: 10;
  padding: 18px 8px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(93, 105, 124, 0.2);
  border-radius: 8px;
  max-width: 370px;
}

.text {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  outline: none;
  border: 1.5px solid transparent;
  padding: 8px;
  font-family: SF Pro Display, sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373530;
  background: transparent;
  border-radius: 4px;
  white-space: nowrap;

  &:hover {
    background: #EBEFF2;
    border-radius: 4px;
    transition: .4s;
  }

  &_choice {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      cursor: default;
      background: transparent;
    }
  }

}

.list {
  padding: 0;
  margin: 0;
}

.card {
  margin: 2px 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border-radius: 4px;
    background-color: #EBEFF2;
  }
}

.icon {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;

  &_black {
    background: #151617;
  }

  &_brown {
    background: #964b00;
  }
}
