.wrapper {
  width: 200px;
  min-height: 254px;
  background: #FFFFFF;
  box-shadow: 0 2px 20px rgba(93, 105, 124, 0.4);
  border-radius: 8px;
  position: absolute;
  top: -10px;
  left: 0;
  padding: 8px;
  z-index: 93;
}

.input {
  width: 140px;
  border: none;
  outline: none;
  background-color: #F5F6F8;
  height: 30px;
  padding: 0 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #373530;

  &::placeholder {
    padding: 0 2px;
    margin-left: 10px;
  }
}

.count {
  height: 30px;
  background-color: #F5F6F8;
  width: 40px;
  margin: 0;
  text-align: end;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.prompt {
  margin: 0;
  padding: 8px 0;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  cursor: default;
  letter-spacing: 0.2px;
  color: #878F93;
}

.choiceTitle {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-top: 5px;
  transition: .3s;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #EBEFF2;
    transition: .3s;
  }

  .title {
    margin: 0 0 0 5px;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #878F93;
  }
}