.language-select {
  white-space: nowrap;
}

.language-select input[name='languages'] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.language-select-2 input[name='languages'] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.language-select .lang-label {
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  margin-right: 24px;
  color: rgba(16, 32, 39, 0.5);
  cursor: pointer;
}

.language-select input[name='languages']:checked + label {
  color: #53A9D7;
}

