.field {

  &:hover .delete {
    opacity: 0.3;
    transition: .3s;
  }

  .delete {
    opacity: 0;

    &:hover {
      cursor: pointer;
      opacity: 1;
      transition: .3s;
    }
  }
}


.title {
  //width: auto;
  //min-width: 100px;
  //max-width: 120px;
  width: 130px;
  min-height: 18px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: rgba(16, 32, 39, 0.5);
  margin: 5px 0 5px 5px;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  word-wrap: break-word;
  cursor: default;
  user-select: none;

  &_action {
    cursor: pointer;
    color: #c1c1c1;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }
}

.valueInput {
  text-decoration: none;
  display: flex;
  align-items: center;
  background: #F5F6F8;
  border-radius: 4px;
  outline: none;
  width: 345px;
  //margin-right: 25px;
  margin: 0 20px 0 auto;
  height: 30px;
  padding: 0 6px;
  color: #373530;

  &_empty {
    border: none;
    background-color: #fff;
    color: #B6B6B6;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    cursor: default;
  }
}