.card {
  min-height: 185px !important;
  width: 330px !important;
  padding: 0 !important;
  border-radius: 10px !important;
  border: none;
  background-color: #ffffff !important;
  //opacity: 1 !important;
  box-shadow: 0 2px 6px rgba(93, 105, 124, 0.2) !important;
  //box-shadow: none !important;

  &_preview {
    box-shadow: none !important;
    height: 200px !important;
    width: 100%;
  }

  &:hover .menu {
    opacity: 1 !important;
    visibility: visible !important;
    transition: .55s opacity, 1s visibility !important;
  }

  &:hover .add {
    opacity: 1 !important;
    visibility: visible !important;
    transition: .55s opacity, 1s visibility !important;
  }
}

.wrapper {
  min-height: 185px;
}

.statusColor {
  height: 10px;
  border-radius: 10px 10px 0 0;
}

.input {
  width: 78%;
  border: none;
  display: block;
  resize: none;
  outline: none;
  font-style: normal;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 24px;
  color: #373530;
  text-align: start;
  margin: 0;

  &::placeholder {
    padding-left: 3px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #C4CCD7;
  }
  &_preview{
    overflow: inherit;
  }
}

.avatar {
  height: 100px;
  width: 70px;
  object-fit: cover;
  object-position: center;

  &_preview {
    height: 160px;
    width: 110px;
  }
}

.callSign {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.2px;
  color: #373530;
}

.item {
  display: flex;
  padding: 16px 16px 0 16px;

  &:last-child {
    padding: 16px;
  }
}

.workingPosition {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
  margin: 5px 0;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status {
  padding: 10px 12px;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  margin: 5px 0;
}

.menu {
  position: absolute;
  opacity: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #EBEFF2;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  border: none;
  outline: none;
  visibility: visible;
  transition: 2s opacity, .55s visibility;

  &_right {
    top: 20px;
    right: 10px;
    transform: translate(0%, 0%);
  }
}

.add {
  position: absolute;
  opacity: 0;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background: #EBEFF2;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  border: none;
  outline: none;
  visibility: visible;
  transition: 2s opacity, .55s visibility;

  &:active {
    background: rgba(55, 53, 48, 0.2);
  }

  &_right {
    top: 50%;
    right: -17px;
    transform: translate(100%, -50%);
  }

  &_left {
    top: 50%;
    left: -17px;
    transform: translate(-100%, -50%);
  }

  &_bottom {
    left: 50%;
    bottom: -17px;
    transform: translate(-50%, 100%);
  }
}


