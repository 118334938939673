.d-ib {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.flex-d-col {
  flex-direction: column;
}

.relative {
  position: relative;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.align-items-center {
  align-items: center;
}

.align-items-f-start {
  align-items: flex-start;
}

.align-self-start {
  align-self: flex-start;
}

.flex-column {
  flex-direction: column;
}

.flex-auto {
  flex: 1 1 auto;
}

.m-auto {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.cup {
  cursor: pointer;
}
