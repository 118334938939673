.menu {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(108%, 0%);
  box-sizing: border-box;
  cursor: default;
  text-align: left;
  min-width: 250px;
  min-height: 100px;
  background: #FFFFFF;
  box-shadow: 0 2px 6px rgba(93, 105, 124, 0.2);
  border-radius: 8px;
  transition: 5s;

}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  padding: 8px;
  margin-bottom: 7px;
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-color: #8585AD #2F2F37;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    padding: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #C4CCD7;
    border: 3px solid #C4CCD7;
    border-radius: 6px;
  }
}

.text {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  outline: none;
  border: 1.5px solid transparent;
  padding: 8px;
  font-family: SF Pro Display, sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373530;
  background: transparent;
  border-radius: 4px;

  &:hover {
    background: #EBEFF2;
    border-radius: 4px;
    transition: .4s;
  }

  &_activeStatus {

    &:hover {
      background: transparent;
      cursor: default;
    }
  }
}

.icon {
  width: 8px;
  height: 8px;
  background: #151617;
  margin-right: 5px;
  border-radius: 50%;
}

.iconColor {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}

.prompt {
  margin: 0;
  padding: 10px 16px 0 16px;
  font-family: SF Pro Display, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  cursor: default;
  letter-spacing: 0.2px;
  color: #878F93;
}

.date {
  cursor: pointer;
  margin: 0 16px 16px;
  font-family: SF Pro Display, sans-serif;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373530;

  &:focus-visible {
    outline: 1px solid transparent;
  }
}

.calendarDateRange {
  font-size: 23px;
  height: 30px;
  margin: 10px 16px 10px 16px;
}

.react-daterange-picker__wrapper {
  border: none !important;
}

.react-daterange-picker__inputGroup {
  min-width: calc(12px + 4.1em) !important;
}