.popup {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    width: 100vw;
    height: 100vh;
    color: tomato;
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
    display: flex;
    justify-content: center;
    align-items: center; 
  }

  .popup_opened {
    visibility: visible;
    opacity: 1;
  }

  .popup__text {
    margin: 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 900;
    height: fit-content;
  }
  
  @media (max-width: 570px) {
    .popup__text {
      font-size: 18px;
      line-height: 22px;
      margin: 17px 9px 0;
    }
  }
  