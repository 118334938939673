.modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  width: 278px;
  border-color: transparent;
  background: #fff;
  box-shadow: 0 2px 6px rgba(93, 105, 124, 0.2);
  border-radius: 8px;
  padding: 32px 24px;
}

.description {
  font-family: SF Pro Display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  margin: 0;
}

.text {
  font-family: SF Pro Display, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #373530;
  margin: 8px 0 0;
}

.btn {
  background: #53A9D7;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  padding: 12px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  transition: .4s;
  border: 2px solid transparent;

  &_cancel {
    background: #fff;
    border: 2px solid #1D8BC5;
    color: #1D8BC5;

    &:hover {
      color: #fff;
    }
  }

  &:hover {
    background: #1D8BC5;
    transition: .4s;
  }
}