.controlPanel {
  /*border: 1px solid green;*/
  display: flex;
  align-items: center;
  max-width: 1680px;
  width: 100%;
  height: 90px;
  margin: 0 auto;
  padding: 0 24px;
  transition: all 0.5s linear;
  user-select: none;
}

.previewPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 2200px;
  width: 100%;
  height: 110px;
  margin: 0 auto;
  padding: 0 24px;
  user-select: none;
}

.padding-left {
  padding-left: 264px;
}

.padding-right {
  padding-right: 324px;
}

.controlPanel__item {
  /*border: 1px solid violet;*/
  box-sizing: border-box;
  background-color: #fff;
  padding: 16px;
  height: 56px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0 2px 6px rgba(93, 105, 124, 0.2);
  border-radius: 8px;
  cursor: pointer;
}

.controlPanel__text {
  font-family: Arial, sans-serif;
  user-select: none;
  cursor: pointer;
  font-style: normal;
  min-width: 150px;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373530;
  margin: 0;
  padding: 0;

  &_preview {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    min-width: 56px;
    width: 86px;
    height: 86px;
    border-radius: 6px;
    margin-right: 20px;
    letter-spacing: 1.5px;
    transition: .3s;

    &:hover {
      background: #EBEFF2;
      transition: .3s;
    }
  }
}

.confirmBtn {
  background: #53A9D7;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  padding: 12px 20px;
  color: #fff;
  font-size: 38px;
  font-weight: 500;
  transition: .4s;
  letter-spacing: 1px;
  border: 3px solid transparent;

  &_cancel {
    background: #fff;
    border:3px solid #1D8BC5;
    color: #1D8BC5;

    &:hover {
      color: #fff;
    }
  }

  &:hover {
    background: #1D8BC5;
    transition: .4s;
  }
}

.share-loader-container {
  position: relative;
  left: 10px;
  border: 1px solid #53a9d7;
  border-radius: 5px;
  width: 203px;
  height: 42px;
  background-color: #f5f6f8;
}
