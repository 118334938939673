.modOrgm {
  position: absolute;
  left: 0;
  top: 70px;
  z-index: 10;
  padding: 18px 8px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(93, 105, 124, 0.2);
  border-radius: 8px;
  max-width: 370px;
}

.list {
  padding: 0;
}

.card {
  margin: 2px 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover .delete {
    opacity: 0.3;
    transition: .3s;
  }
  .delete {
    padding: 5px;
    opacity: 0;
    &:hover {
      cursor: pointer;
      opacity: 1;
      transition: .3s;
    }
  }
  &:hover {
    border-radius: 4px;
    background-color: #EBEFF2;
  }
}



.text {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 8px;
  margin: 0;
  font-family: SF Pro Display, sans-serif;
  font-style: normal;
  font-weight: normal;
  white-space: nowrap;
  cursor: default;
}




.text_choice {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.5);
}

.text_bark {
  cursor: pointer;
  transition: .3s;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373530;
}


.text_create {
  cursor: pointer !important;
  color: #878F93;
}

.text_create:hover {
  color: #5a6265;
  transition: .3s;
}