.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  width: 600px;
  border-color: transparent;
}

.modal__item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px 30px 20px 30px;
  min-height: 570px;
  max-height: 570px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.menu {
  width: 32px;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 3px;
  transition: .3s;
  &:hover {
    background-color: #EBEFF2;
    transition: .3s;
  }
}

.status {
  height: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.headerInfo {
  display: flex;
  //width: 100%;
  margin: 15px 0 30px 0;
}

.avatarWrapper {
  position: relative;
}

.avatarInput {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.avatarImage {
  width: 58px;
  height: 80px;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
}

.input {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.2px;
  color: #373530;
  display: block;
  border: none;
  outline: none;

  &::placeholder {
    padding-left: 3px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #C4CCD7;
  }

  &_callSing {
    text-align: end;
    margin: 0 0 0 auto;
  }

}

.inputWorkingPosition {
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.2px;
  //color: #373530;
  display: block;
  border: none;
  outline: none;
  color: #102027;
  opacity: 0.5;
  font-size: 16px;
  line-height: 20px;
}


.text {
  color: rgba(16, 32, 39, 0.5);
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.modal__title {
  margin: 0;
}

.textarea {
  background: #F5F6F8;
  border-radius: 8px;
  outline: none;
  padding: 16px 28px;
  border: 1px solid transparent;
  /*resize: vertical;*/
  resize: none;
  /*min-height: 75px;*/
}

.modal__textarea:focus {
  border: 1px solid #53A9D7;
}

::-webkit-scrollbar {
  background-color: #FBF9FF;
  width: 6px;
  padding: 0;
}

::-webkit-scrollbar-thumb {
  background: #959499;
  border-radius: 15px;
}
