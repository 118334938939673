.document {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;

  .text {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #10202780;
    user-select: none;
    cursor: pointer;
    margin: 3px 0;

    &:hover {
      opacity: 0.8;
    }

    &_date {
      margin-left: 5px;

      &:hover {
        opacity: 1;
      }
    }

    &_file {
      color: #102027;
      width: 300px;
      cursor: default;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover .delete, .share {
    opacity: 0.3;
    transition: .3s;
  }

  .delete {
    margin-left: 5px;
    opacity: 0;

    &:hover {
      cursor: pointer;
      opacity: 1;
      transition: .3s;
    }
  }
}

.add {
  width: 130px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: rgba(16, 32, 39, 0.5);
  margin: 5px 0 5px 5px;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  word-wrap: break-word;
  outline: none;
  cursor: pointer;
  object-fit: cover;
  object-position: center;

  &_action {
    cursor: pointer;
    color: #c1c1c1;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }
}

.inputFile {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}


.share {
  width: 16px;
  height: 16px;
  margin: 0 5px;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: .3s;
  }
}