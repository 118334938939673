.authForm {
  background: #f2f7f9;
  width: 344px;
  margin: 120px auto 0;

  &__title {
    font-size: 28px;
    font-family: "Arial", sans-serif;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }

  &__inputWrapper {
    border: solid 2px #c4ccd7;
    border-radius: 8px;
    background-color: white;
    margin-top: 16px;
    height: 56px;
    display: flex;
    align-items: center;
    padding-right: 18px;
  }

  &__inputWrapper:first-of-type {
    margin-top: 48px;
  }

  &__input {
    border: unset;
    height: 90%;
    padding: 0;
    margin-left: 10px;
    width: 100%;
    outline: none;
    font-size: 18px;
    font-family: "Arial", sans-serif;
    background: white;
  }

  &__input::placeholder {
    font-size: 18px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-left: 18px;
  }

  &__passwordButton {
    width: 16px;
    height: 16px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    background-color: white;
    border: unset;
  }

  &__button {
    height: 56px;
    width: 100%;
    border: unset;
    border-radius: 8px;
    background-color: #53a9d7;
    color: white;
    margin-top: 16px;
    font-family: "Arial", sans-serif;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    cursor: pointer;
  }

  &__button:hover {
    background-color: #3f97c7;
  }

  &__button:active {
    background-color: #3284af;
  }

  &__button:disabled {
    background-color: #C4CCD7;
  }

  &__google-link {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color:black;
    height: 56px;
    width: 100%;
    background-color: #f2f7f9;
    border: solid 2px #53a9d7;
    border-radius: 8px;
    font-family: "Arial", sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-top: 32px;
    cursor: pointer;
    text-decoration: none;
  }

  &__text {
    text-align: center;
    margin-top: 32px;
    font-family: "Arial", sans-serif;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__link {
    display: table;
    text-decoration: none;
    color: #1d8bc5;
    font-size: 14px;
    margin: 32px auto 0;
  }



  &__checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__custom-checkbox {
    display: inline-flex;
    align-items: center;
    user-select: none;
    position: relative;
  }

  &__custom-checkbox::before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__custom-eye {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__forgotPass {
    display: block;
    color: #1d8bc5;
    text-decoration: none;
    font-family: "Arial", sans-serif;
    font-size: 14px;
    margin-top: 6px;
    margin-left: auto;
    width: fit-content;
  }

  &__forgotPass:hover {
    text-decoration: underline;
  }
}

.forgotPass_text {
  margin: 0
}

.validation_error {
  color: #E57373;
  font-size: 14px;
}
