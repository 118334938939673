.calendarWrapper {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(3%, 10%);
  z-index: 95;
}

.calendar {
  border: 1px solid #53a9d7;
  //box-shadow: 0 2px 6px rgb(93 105 124 / 20%);
  border-radius: 5px;
}

.close {
  position: absolute;
  top: -22px;
  right: -22px;
  cursor: pointer;
}