.file_container {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.file_view_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: auto;
}


.download_btn {
  background-color: #FFFFFF;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: unset !important;
  height: unset !important;
}

.pg-viewer-wrapper .document-container {
  width: 50% !important;
}
