.react-daterange-picker {
  &__wrapper {
    border: none !important;
  }
}

.react-daterange-picker {
  &__inputGroup {
    min-width: 50px !important;
  }
}


.custom_wrapper {
  border: none !important;
  font-family: "Arial", sans-serif !important;
  font-size: 23px !important;
}

.custom_inputGroup {
  min-width: 50px !important;
  font-family: "Arial", sans-serif !important;
  font-size: 23px !important;
}
