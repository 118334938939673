.side-panel {
  position: fixed;
  top: 0;
  left: -240px;
  transition: all 0.5s linear;
  width: 240px;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  color: #878f93;
  padding: 24px 20px;
  z-index: 10;

  &_footer {
    position: absolute;
    top: 96%;
  }

  &__show {
    left: 0;
  }

  &__nav {
    margin-top: 50px;
  }

  &__link {
    font-family: "Arial", sans-serif;
    color: #878f93;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  &__text {
    font-family: "Arial", sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-left: 15px;
  }
}

.user {
  width: 200px;
  min-height: 75px;
  margin: auto;
  border-bottom: solid 1px #ebeff2;
  display: flex;

  &__avatar {
    min-width: 40px;
    height: 40px;
    background-color: #F5F6F8;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    margin-left: 8px;
    overflow: hidden;
  }

  &__name {
    margin: 0;
    color: black;
    font-family: "Arial", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__email {
    margin: 0;
    margin-top: 4px;
    font-family: "Arial", sans-serif;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
