.share {
  min-height: 40px;
  min-width: 150px;
  background-color: #F5F6F8;
  display: flex;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-10%, -100%);
  padding: 7px;
  border: 1px solid #53a9d7;
}
