.text {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #10202780;
  user-select: none;
  cursor: default;
  margin: 8px;
  text-align: start;
  width: 70%;


  &_date {
    margin-left: 5px;
    width: auto;

    &:hover {
      opacity: 1;
    }
  }

}